<template>
  <div>

    <b-modal id="confirm-delete-project" ref="ref-confirm-delete-project" title="Удалить проект?" hide-footer>
      <p class="my-4">Вы уверены что хотите удалить проект?</p>
      <div class="d-flex flex-row col-12 justify-content-between">
        <b-button variant="danger" @click="deleteProject">Да</b-button>
        <b-button variant="outline-secondary" @click="hideModal">Нет</b-button>
      </div>
    </b-modal>


    <b-overlay :show="dataLoading" rounded="sm">

      <!-- Новый клиент -->
      <modal-new-project
          :isActive="isAddNewProjectSidebarActive"
          @addNewProject="addNewProject"
      />

      <b-card
          no-body
          class="mb-2"
      >
        <div class="m-2">
          <h5 class="mb-1">Фильтр и соритровка списка проектов</h5>
          <b-row class="d-flex flex-wrap align-items-center">

            <!-- Владелец... -->
            <b-col v-if="$store.state.app.user && $store.state.app.user.account_type !== 'CLIENT'"
                   cols="12" xl="7"
                   class="d-flex align-items-center mb-1 mb-xl-0"
            >
              <label for="manager"> Выберите владельца проекта </label>
              <v-select
                  class="col-9 col-md-11"
                  id="manager"
                  v-model="filters.selected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="filters.options"
                  :clearable="false"
                  value="id"
                  label="fullName"
                  @input="searchFromProjects()"
              />
            </b-col>

            <!-- Поиск -->
            <b-col cols="12" xl="5">
              <b-form-input
                  type="number"
                  v-model="searchModel"
                  @input="searchFromProjects()"
                  class=""
                  placeholder="Поиск по номеру заказа"
              />
            </b-col>
          </b-row>
        </div>
      </b-card>

      <!-- Враппер таблицы -->
      <b-card no-body class="mb-0">

        <!-- Шапка таблицы -->
        <div class="m-2">
          <b-row class="justify-content-between">

            <!-- Показывать... -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Показывать</label>
              <v-select
                  v-model="pagination.perPage"
                  :options="pagination.options"
                  :clearable="false"
                  @input="loadProjects(pagination.perPage, offset, table.sort.desc, table.sort.typeSort, searchModel)"
                  class="per-page-selector d-inline-block mx-50"
              />
            </b-col>

            <!--  Новый клиент  -->
            <b-button
                variant="primary"
                class="mr-1 col-sm-4 col-md-3 col-xl-3 col-12"
                @click="isAddNewProjectSidebarActive =! isAddNewProjectSidebarActive"
            >
              <span class="text-nowrap">+ Новый проект</span>
            </b-button>
          </b-row>
        </div>

        <!--  Таблица  -->
        <b-table
            @sort-changed="sorting"
            ref="refUserListTable"
            class="position-relative"
            :items="table.projects"
            :per-page="pagination.perPage"
            responsive
            :fields="table.tableColumns"
            primary-key="id"
            show-empty
            empty-text="Нет подходящих результатов"
        >

          <!-- Номер проекта -->
          <template #cell(id)="data">
            <b-media vertical-align="center">
              <a @click="$router.push(`/project/${data.item.id}`)" class="text-primary">{{ data.item.id }}</a>
            </b-media>
          </template>

          <!-- Название проекта -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <a @click="$router.push(`/project/${data.item.id}`)" class="text-primary">{{ data.item.title }}</a>
            </b-media>
          </template>

          <!-- Статус клиента -->
          <template #cell(status)="data">
            <b-badge
                pill
                :variant="`light-${resolveOrderStatusVariant(data.item.status)}`"
                class="text-capitalize"
            >
              {{ resolveOrderStatusLabel(data.item.status) }}
            </b-badge>
          </template>

          <!-- Дата регистрации -->
          <template #cell(project_date)="data">
            <b-media vertical-align="center">
              <span>{{ data.item.create_at | moment("DD.MM.YYYY HH:MM") }}</span>
            </b-media>
          </template>

          <!--  Владелец  -->
          <template #cell(creator)="data">
            <b-media vertical-align="center">
              <template #aside>

                <!-- Todo Params  -->
                <b-avatar
                    size="32"
                    :src="data.item.owner.avatar"
                    :text="(getFullname(data.item.owner))"
                />
              </template>
              <b-link
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ getFullname(data.item.owner) }}
              </b-link>
            </b-media>
          </template>

          <!-- Управление -->
          <template #cell(actions)="data">
            <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'project-page', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">Изменить проект</span>
              </b-dropdown-item>
              <b-dropdown-item @click="copyProject(data.item)">
                  <feather-icon icon="CopyIcon"/>
                  <span class="align-middle ml-50">Копировать проект</span>
              </b-dropdown-item>
              <b-dropdown-item @click="getIdDeletedProject(data.item.id)" v-b-modal.confirm-delete-project>
                <feather-icon icon="TrashIcon"/>
                <span class="align-middle ml-50">Удалить проект</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <!-- Карточка пагинации -->
        <div class="mx-2 mb-2">
          <b-row>

            <!--  Показаны xx из xx результатов -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
              >Показаны {{ offset + 1 }} - {{ offset + (table.projects.length) }} из
                {{ pagination.rows }} результатов</span
              >
            </b-col>

            <!-- Паджинация -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                  :per-page="pagination.perPage"
                  v-model="pagination.currentPage"
                  :total-rows="pagination.rows"
                  @input="changePage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BModal,
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ModalNewProject from '@/components/views/projects/modals/ModalNewProject.vue'
import utils from '@/utils'


import api from '@/api'

export default {
  components: {
    BModal,
    BFormSelect,
    ModalNewProject,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,

    vSelect,
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        options: [10, 25, 50, 100],
        rows: 100,
      },
      filters: {
        options: [
          {value: null, fullName: "Все"}
        ],
        ids: [],
        selected: null
      },

      myId: null,
      idDeleted: null,
      table: {
        projects: [],
        tableColumns: [
          {key: 'id', label: 'Номер проекта', sortable: true},
          {key: 'title', label: 'Навзвание проекта', sortable: true},
          {key: 'project_date', label: 'Дата создания', sortable: true},
          {key: 'status', label: 'Статус проекта', sortable: true},
          {key: 'creator', label: 'Владелец проекта', sortable: false},
          {key: 'actions', label: 'Управление'},
        ],
        sort: {
          typeSort: '-create_at',
          desc: ''
        }
      },
      dataLoading: true,
      searchModel: '',
      isAddNewProjectSidebarActive: false,
    };
  },
  mounted() {
    this.getMyId()
    this.loadProjects(this.pagination.perPage, this.offset, this.table.sort.desc, this.table.sort.typeSort, this.searchModel);
  },

  methods: {
    getFullname(user) {
      return user.fullName;
    },
    //Фильрация
    searchFromProjects() {
      this.loadProjects(
          this.pagination.perPage,
          this.offset,
          this.table.sort.desc,
          this.table.sort.typeSort,
          this.searchModel,
          this.filters.selected ? this.filters.selected.id : null
      );
    },

    // Методы для таблицы
    loadProjects(limit, offset, desc, sortType, id, owner) {
      this.dataLoading = true
      const self = this
      api.projects
          .getProjects(limit, offset, desc, sortType, id, owner)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              self.table.projects = data.result.projects;
              self.pagination.rows = data.result.total_count;
              for (let project of self.table.projects) {
                project.owner.fullName = `${project.owner.last_name} ${project.owner.first_name} ${project.owner.middle_name}`;
                project.client.fullName = `${project.client.last_name} ${project.client.first_name} ${project.client.middle_name}`;
                if (this.filters.selected == null) {
                  if (!this.filters.ids.includes(project.owner.id)) {
                    this.filters.ids.push(project.owner.id)
                    this.filters.options.push(project.owner)
                  }
                }
              }
              self.dataLoading = false;
            }
          })
          .catch((err) => {
            if (err.response) {
              const data = err.response.data;
              if (data && data.error) {
                utils.notificateError(self, data.message, data.errorCode);
              }
            }
          })
          .finally(() => {
            self.dataLoading = false
          });
    },
    changePage() {
      this.searchModel = ''
      console.log(this.offset)
      this.loadProjects(
          this.pagination.perPage,
          this.offset,
          this.table.sort.desc,
          this.table.sort.typeSort,
          this.searchModel,
          this.filters.selected ? this.filters.selected.id : null
      )
    },
    sorting(ctx) {
      if (ctx.sortDesc == true) {
        this.table.sort.desc = '-'
      } else
        this.table.sort.desc = ''
      this.table.sort.typeSort = ctx.sortBy
      const self = this;
      self.dataLoading = true;
      this.searchModel = ''
      this.loadProjects(this.pagination.perPage, this.offset, this.table.sort.desc, this.table.sort.typeSort, this.searchModel, this.filters.selected.id)
    },

    // Методы для добавления нового проекта
    addNewProject(data) {
      this.dataLoading = true
      api.projects
          .addNewProject(data.title, this.myId)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.isAddNewProjectSidebarActive = !this.isAddNewProjectSidebarActive
              utils.notificate(this, data.message)
              this.loadProjects(this.pagination.perPage, this.offset, this.table.sort.desc, this.table.sort.typeSort, this.searchModel);
              this.dataLoading = false
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    async copyProject(item) {
      this.dataLoading = true
      api.projects
          .copyProject(item.id)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              utils.notificate(this, "Проект успешно скопирован")
              this.loadProjects(this.pagination.perPage, this.offset, this.table.sort.desc, this.table.sort.typeSort, this.searchModel);
              this.dataLoading = false
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    getMyId() {
      api.users
          .me()
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.myId = data.result.id
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },

    // Методы dropDown меню
    deleteProject() {
      this.dataLoading = true
      api.projects
          .deleteProject(this.idDeleted)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              utils.notificate(this, data.message)
              this.loadProjects(this.pagination.perPage, this.offset, this.table.sort.desc, this.table.sort.typeSort, this.searchModel);
              this.hideModal()
              this.dataLoading = false
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    hideModal() {
      this.$refs['ref-confirm-delete-project'].hide()
    },
    getIdDeletedProject(id) {
      this.idDeleted = id
    },

    // Отрисовка статуса
    resolveOrderStatusVariant(status) {
      if (status === 'COMPLETED') return 'success'
      if (status === 'IN_PROGRESS') return 'warning'
      if (status === 'DRAFT') return 'primary'
      else return 'danger'
    },
    resolveOrderStatusLabel(status) {
      if (status === 'COMPLETED') return 'Завершен'
      if (status === 'IN_PROGRESS') return 'В работе'
      if (status === 'DRAFT') return 'Черновик'
      else return 'Отклонен'
    },


  },
  computed: {
    offset() {
      return (this.pagination.currentPage - 1) * this.pagination.perPage
    },
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
